<template>
	<div class="layout-footer mt-4">
		<img alt="Logo Tecnalia" src="images/tecnalia.png" height="20" class="mr-2" />
		<div class="flex-grow-1"></div>
		<img alt="Logo EIT" src="images/logo-eit.png" class="mr-2" />
	</div>
</template>

<script>
	export default {
		name: "AppFooter"
	}
</script>