<template>
	<div class="layout-topbar">
		<router-link to="/home" class="layout-topbar-logo text-white">
			<i class="pi pi-home mr-2"></i> <span>Home</span>
		</router-link>

		

		<h2 class="text-white mt-0 mb-0">{{title}}</h2>

		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<!--
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-home"></i>
					<span>Home</span>
				</button>
			</li>
		</ul>
		-->
	</div>
</template>

<script>
export default {
	data() {
		return {
			title: ""
		}
	},
    methods: {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		topbarImage() {
			return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
		}
    },
	watch: {
        $route: {
            immediate: true,
            handler(to) {
                this.title = to.meta.title || '';
            }
        },
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	}
}
</script>