import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
});

const store = new createStore({
    plugins: [vuexLocal.plugin],
    state: {
        authenticated: false,
        username: null
    },
    mutations: {
        setAuthentication(state, status) {
            state.authenticated = status;
        },
        setUsername(state, username) {
            state.username = username;
        },
        logout(state) {
            state.authenticated = false;
            state.username = null;
        },
    },
    actions: {},
    modules: {},
    strict: process.env.NODE_ENV !== "production",
});

export default store;
