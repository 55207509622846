import { createRouter, createWebHashHistory } from 'vue-router';
import App from './App.vue';

const routes = [
    {
        path: '/',
        name: 'app',
        component: App,
        redirect: '/home',
        children: [
            {
                path: '/home',
                name: 'home',
                component: () => import('./components/Home.vue'),
                meta: { title: '' }
            },
            {
                path: '/process-data',
                name: 'process-data',
                component: () => import('./components/ProcessData.vue'),
                meta: { title: 'Process data' }
            },
            {
                path: '/simulation',
                name: 'simulation',
                component: () => import('./components/Simulation.vue'),
                meta: { title: 'Simulation' }
            },
            {
                path: '/material-properties',
                name: 'material-properties',
                component: () => import('./components/MaterialProperties.vue'),
                meta: { title: 'Material properties' }
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/landing',
        name: 'landing',
        component: () => import('./pages/LandingDemo.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
